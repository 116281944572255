import { createSlice } from '@reduxjs/toolkit'

import { distinctArrayByKey, parseMarket } from '../utils'
import { DEFAULT_TAGS, CATEGORIES } from '../config'

export const appSlice = createSlice({
  name: 'events',

  initialState: {
    data: [],
    tags: [],
    activeCategory: CATEGORIES[0],
  },

  reducers: {
    setEvents: (state, action) => {
      state.data = action.payload.map(event => {
        return {
          ...event,
          markets: event.markets.filter(market => Boolean(market.outcomePrices) && Boolean(market.outcomes)).map(parseMarket),
        }
      })
    },
    setTags: (state, action) => {
      state.tags = distinctArrayByKey(action.payload, 'id')
    },
    setActiveCategory: (state, action) => {
      state.activeCategory = action.payload
    },
  },
})

const slice = {
  reducer: appSlice.reducer,
  set: appSlice.actions,
}

export default slice
