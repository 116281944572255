export const POLYMARKET_API_URL = 'https://clob.polymarket.com'
export const GAMMA_API_URL = 'https://gamma-api.polymarket.com'
export const WS_POLYMARKET_API_URL = 'wss://ws-subscriptions-clob.polymarket.com/ws'
export const API_URL = 'https://api.dev.odenlabs.co'

export const MIN_ORDER_QUANTITY = 5
export const USDT_MASTER_ADDRESS = 'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs'

export const DEFAULT_TAGS = [{
  label: 'Top',
  slug: 'top-events',
  id: 'top-events',
  forceShow: true,
}, {
  label: 'New',
  slug: 'new-events',
  id: 'new-events',
  forceShow: true,
}]

export const CATEGORIES = [{
  label: 'All',
  slug: 'top-events',
  id: 'top-events',
}, {
  label: 'New',
  slug: 'new-events',
  id: 'new-events',
}, {
  label: 'Politics',
  slug: 'politics',
  id: 'politics',
}, {
  label: 'Crypto',
  slug: 'crypto',
  id: 'crypto',
}, {
  label: 'Pop Culture',
  slug: 'pop-culture',
  id: 'pop-culture',
}, {
  label: 'Business',
  slug: 'business',
  id: 'business',
}, {
  label: 'Science',
  slug: 'science',
  id: 'science',
}]