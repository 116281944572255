import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Radio, Typography, message } from 'antd'
import cn from 'classnames'
import numeral from 'numeral'
import { useTonConnectUI } from '@tonconnect/ui-react'
import { CloseOutlined } from '@ant-design/icons'

import styles from './styles.module.scss'
import AppButton from '../Button'
import InputNumber from '../InputNumber'
// import OrderBook from '../OrderBook'
import { createOrder, getActiveOrders, getOrderBook, getBalance } from '../../api'
import $app from '../../store/app'
import $orders from '../../store/orders'
import { MIN_ORDER_QUANTITY } from '../../config'
import { wait } from '../../utils'

const getPrice = (asks, spread) => {
  if (asks && Array.isArray(asks)) {
    const [lowest] = [...asks].reverse()
    return (lowest?.price || 0) * (1 + (spread+0.2) / 100)
  }
  return 0
}

const getPotentialWin = (price, quantity) => {
  const decimal = 100/(price*100)
  const amount = quantity*decimal
  return {
    price: numeral(price*100).format('0.0[0]'),
    decimal: numeral(decimal).format('0.0[0]'),
    amount: numeral(amount).format('0.0[0]'),
    percentage: numeral((amount - quantity) / quantity * 100).format('0.0[0]')
  }
}

const BetForm = () => {
  const dispatch = useDispatch()
  const [tonConnectUI] = useTonConnectUI()
  const [messageApi, contextHolder] = message.useMessage()

  const formPreset = useSelector(({app}) => app.formPreset)
  const spread = useSelector(({app}) => app.spread)

  const [outcomeIndex, setOutcomeIndex] = useState(0)
  const [quantity, setQuantity] = useState(1)

  const [tokensOrderBook, setTokensOrderBook] = useState({})
  

  const currentToken = formPreset.market?.clobTokenIds[outcomeIndex]

  const tokenPrices = formPreset.market?.clobTokenIds.reduce((acc, tokenId) => {
    return {...acc, [tokenId]: getPrice(tokensOrderBook[tokenId], spread)}
  }, {})

  // console.log(tokensOrderBook[currentToken])

  const potentialWin = getPotentialWin(tokenPrices?.[currentToken], quantity)

  useEffect(() => {
    setOutcomeIndex(formPreset.outcomeIndex)
  }, [formPreset.outcomeIndex])

  useEffect(() => {
    if (formPreset.market?.clobTokenIds) {
      fetchOrderBooks()
    }
  }, [formPreset.market?.clobTokenIds])


  const fetchOrderBooks = () => {
    const promises = formPreset.market.clobTokenIds.map(tokenId => {
      return getOrderBook(tokenId).then(res => {
        if (res) {
          return {[tokenId]: res.asks}
        }
      })
    })

    Promise.all(promises).then(res => {
      const orderBooks = res.reduce((acc, item) => {
        return {...acc, ...item}
      }, {})
      setTokensOrderBook(orderBooks)
    })
  }

  const handleChangeType = ({target}) => {
    setOutcomeIndex(formPreset.market?.outcomes.indexOf(target.value))
  }

  const handleClose = () => {
    dispatch($app.set.closeForm())
  }

  const handleSubmit = () => {
    if (!tonConnectUI.connected) {
      tonConnectUI.openModal()
      return
    }
    const price = numeral(tokenPrices[currentToken]).format('0.[000]')*1
    console.log(price)
    if (quantity/price < MIN_ORDER_QUANTITY) {
      messageApi.open({
        type: 'error',
        content: `Shares should be more than ${MIN_ORDER_QUANTITY}`,
      })
      return
    }
    const post = {
      quantity: numeral(quantity/price).format('0')*1,
      price: price,
      tokenID: currentToken,
      side: 'BUY',
      isNegRisk: formPreset.market?.negRisk ?? false,
    }

    createOrder(post).then(async res => {
      if (res.error) {
        messageApi.open({
          type: 'error',
          content: res.error,
        })
        return
      }
      if (res.orderID) {
        messageApi.open({
          type: 'success',
          content: res.message,
        })
        await wait(2000)
        getBalance().then(res => {
          if (res && res.message === 'Success') {
            dispatch($app.set.setBalance(res.balance))
          }
        })
        getActiveOrders().then(res => {
          if (res && res.message === 'Success') {
            dispatch($orders.set.setOrders(res.activeOrders))
          }
        })
        dispatch($app.set.closeForm())
      }
    })
  }

  const buttonColor = outcomeIndex === 0 ? 'success' : 'danger'

  return (
    <div className={styles.container}>
      {contextHolder}
      <div className={styles.header}>
        <img src={formPreset.market?.image} className={styles.image} />
        <div className={styles.title}>{ formPreset.market?.question }</div>
        <div onClick={handleClose} className={styles.closeButton}>
          <CloseOutlined style={{fontSize: 24}} />
        </div>
      </div>
      <div>
        <Radio.Group
          buttonStyle="solid"
          style={{display: 'flex'}}
          onChange={handleChangeType}
          value={formPreset.market?.outcomes[outcomeIndex]}>
          {
            formPreset.market?.outcomes?.map((btn, i) => {
              const btnKey = btn.toLowerCase()
              const isActive = i === outcomeIndex
              const price = Object.values(tokenPrices)[i]
              const priceFormatted = `${numeral(price*100).format('0')}¢`
              return (
                <Radio.Button
                  key={btnKey}
                  value={btn}
                  className={cn(styles.sideButton, {[styles.active]: isActive, [styles.buy]: i === 0, [styles.sell]: i === 1})}>
                  Bet { btn } { priceFormatted }
                </Radio.Button>
              )
            })
          }
        </Radio.Group>
      </div>
      <div>
        <Typography.Text className={styles.title}>Total bet amount</Typography.Text>
        <InputNumber
          value={quantity}
          decimals={true}
          onChange={val => setQuantity(val)} />
      </div>
      <div>
      < div className={styles.row}>
          <div className={styles.property}>Avg price</div>
          <div style={{color: '#1652f0'}}>{ potentialWin.price }¢</div>
        </div>
        <div className={styles.row}>
          <div className={styles.property}>Shares</div>
          <div>{ potentialWin.amount }</div>
        </div>
        <div className={styles.row}>
          <div className={styles.property}>Potential return</div>
          <div style={{color: '#27AE60'}}>${ potentialWin.amount } ({potentialWin.percentage}%)</div>
        </div>
      </div>
      {/* <OrderBook data={{asks: tokensOrderBook[currentToken]}} /> */}
      <AppButton style={{}} color={buttonColor} onClick={handleSubmit}>
        Bet
      </AppButton>
    </div>
  )
}

export default BetForm
