import { Input, Space, Button } from 'antd'

const AppInputNumber = ({ value, onChange, decimals }) => {

  const handleChange = ({target}) => {
    let formattedValue = decimals ? target.value.replace(/[^0-9.]/g, '') : target.value.replace(/[^0-9]/g, '')
    if (decimals) {
      const dotIndex = formattedValue.indexOf('.')
      if (dotIndex !== -1) {
        formattedValue = formattedValue.slice(0, dotIndex + 1) + formattedValue.slice(dotIndex + 1).replace(/\./g, '')
      }
    }
    
    onChange(formattedValue)
  }

  const handleIncrease = (direction) => () => {
    onChange(parseFloat(value || 0) + direction)
  }

  return (
    <Space.Compact style={{ width: '100%' }}>
      <Button size="large" type="default" onClick={handleIncrease(-1)}>-</Button>
      <Input
        placeholder="$0"
        value={`$${value}`}
        onChange={handleChange} />
      <Button size="large" type="default" onClick={handleIncrease(1)}>+</Button>
    </Space.Compact>
  )
}

export default AppInputNumber
