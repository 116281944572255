import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { Typography, message } from 'antd'

import styles from './styles.module.scss'
import AppButton from '../Button'
import AppInputNumber from '../InputNumber'
import { withdraw } from '../../api'
import $app from '../../store/app'

const DepositForm = () => {
  const dispatch = useDispatch()
  const [messageApi, contextHolder] = message.useMessage()

  const [amount, setAmount] = useState(0)

  const handleWithdraw = () => {
    withdraw(amount).then(res => {
      if (res?.error) {
        messageApi.open({
          type: 'error',
          content: res.error,
        })
        return
      }
      messageApi.open({
        type: 'success',
        content: res.message,
      })
      dispatch($app.set.setWithdrawForm(false))
    })
  }

  return (
    <div className={styles.depositForm}>
      {contextHolder}
      <Typography.Text className={styles.title}>Amount</Typography.Text>
      <AppInputNumber
        decimals={true}
        value={amount}
        onChange={val => setAmount(val)} />
      <AppButton color={'success'} onClick={handleWithdraw}>
        Withdraw
      </AppButton>
    </div>
  )
}

export default DepositForm
