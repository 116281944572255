import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { message } from 'antd'
import numeral from 'numeral'
import { Button } from 'antd'

import styles from './styles.module.scss'
import { cashOutOrder, getActiveOrders, getOrderBook, getBalance } from '../../api'
import $orders from '../../store/orders'
import $app from '../../store/app'
import { parseMarket } from '../../utils'

const Orders = () => {
  const dispatch = useDispatch()
  const spread = useSelector(({app}) => app.spread)

  const [messageApi, contextHolder] = message.useMessage()

  const orders = useSelector(({orders}) => orders.data)

  const [currentPrices, setCurrentPrices] = useState({})

  const uniqueAssets = [...new Set(orders.map(o => o.asset_id))]

  useEffect(() => {
    if (uniqueAssets.length) {
      fetchCurrentPrices(uniqueAssets)
    }
  }, [uniqueAssets])

  const fetchCurrentPrices = async (ids) => {
    const promises = ids.map(id => getOrderBook(id))
    const orderBooks = await Promise.all(promises)
    const prices = orderBooks.map(book => {
      const [highest] = book.bids.reverse()
      return {
        asset_id: book.asset_id,
        price: highest.price,
      }
    }).reduce((acc, curr) => {
      return {
        ...acc,
        [curr.asset_id]: curr.price,
      }
    }, {})
    setCurrentPrices(prices)
  }

  const handleCashOut = (order) => async () => {
    const orderBook = await getOrderBook(order.asset_id)
    const [highest] = orderBook.bids.reverse()
    
    cashOutOrder({
      orderID: order.order_id,
      price: numeral(highest.price * (1 - spread / 100)).format('0.[0000]')*1,
      isNegRisk: order.market_data?.negRisk ?? false,
    }).then(res => {
      if (res.error) {
        messageApi.open({
          type: 'error',
          content: res.error,
        })
        return
      }
      messageApi.open({
        type: 'success',
        content: res.message,
      })
      getBalance().then(res => {
        if (res && res.message === 'Success') {
          dispatch($app.set.setBalance(res.balance))
        }
      })
      getActiveOrders().then(res => {
        if (res && res.message === 'Success') {
          dispatch($orders.set.setOrders(res.activeOrders))
        }
      })
    })
  }
  
  return (
    <div className={styles.container}>
      {contextHolder}
      <div className={styles.orders}>
        {
          [...orders].reverse().map((order) => {
            const market = parseMarket(order.market_data)
            const tokenIndex = market.clobTokenIds.indexOf(order.asset_id)
            const currentTokenName = market.outcomes[tokenIndex]
            const cashoutPrice = numeral(currentPrices[order.asset_id] * (1 - spread / 100)).value()
            const cashoutAmount = numeral(cashoutPrice * order.size).format('0.0[000]')
            const orderAmount = numeral(order.price * order.size).format('0.0[000]')

            const diff = numeral(cashoutAmount).subtract(orderAmount).value()
            const percentage = numeral(diff).divide(orderAmount).multiply(100).value()
            return (
              <div key={order.order_id} className={styles.orderContainer}>
                <div className={styles.header}>
                  <img src={order.market_data.image} className={styles.image} />
                  <div className={styles.question}>
                    { order.market_data.question }
                  </div>
                </div>
                <div className={styles.row}>
                  <div>
                    <div className={styles.side} style={{backgroundColor: tokenIndex ? '#d63031' : '#00b894'}}>
                      { currentTokenName }
                    </div>
                  </div>
                  <div className={styles.prices}>
                    <div className={styles.price}>${ cashoutAmount }</div>
                    <div className={styles.price} style={{color: diff < 0 ? '#d63031' : '#00b894'}}>
                      {diff < 0 ? '-' : '+'}${ Math.abs(diff) } ({ numeral(percentage).format('0.00') }%)
                    </div>
                  </div>
                </div>
                <div className={styles.row}>
                  <div>
                  </div>
                  <Button
                    type="primary"
                    size='small'
                    style={{fontWeight: 700}}
                    onClick={handleCashOut(order)}>
                    Cashout
                  </Button>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default Orders
