import { useSelector } from 'react-redux'
import { useState } from 'react'
import { Typography } from 'antd'
import { useTonConnectUI, useTonAddress } from '@tonconnect/ui-react'
import { getHttpEndpoint } from '@orbs-network/ton-access'

import styles from './styles.module.scss'
import AppButton from '../Button'
import AppInputNumber from '../InputNumber'
import { USDT_MASTER_ADDRESS } from '../../config'

const calculateUsdtAmount = (usdCents) => {
  return BigInt(usdCents * 10000)
}

const DepositForm = () => {
  const [tonConnectUI] = useTonConnectUI()
  const walletAddress = useTonAddress()
  const depositAddress = useSelector(({app}) => app.depositAddress)

  const [amount, setAmount] = useState(0)

  const handleDeposit = async () => {
    
    const endpoint = await getHttpEndpoint({network: 'mainnet'})
    const core = await import('ton-core')
    const ton = await import('ton')
    const { JettonWallet } = await import('../../utils/JettonWallet.ts')
    const tonClient = new ton.TonClient({endpoint: endpoint})
    const jettonMaster = tonClient.open(ton.JettonMaster.create(core.Address.parse(USDT_MASTER_ADDRESS)))
    const usersUsdtAddress = await jettonMaster.getWalletAddress(core.Address.parse(walletAddress))
    const jettonWallet = tonClient.open(JettonWallet.createFromAddress(usersUsdtAddress))

    const sender = {
      send: async (args) => {
        await tonConnectUI.sendTransaction({
          messages: [
            {
              address: args.to.toString(),
              amount: args.value.toString(),
              payload: args.body?.toBoc()?.toString('base64'),
            },
          ],
          validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes for user to approve
        });
      },
      address: core.Address.parse(walletAddress),
    }

    const res = await jettonWallet.sendTransfer(sender, {
      fwdAmount: 1n,
      jettonAmount: calculateUsdtAmount(amount*100),
      toAddress: core.Address.parse(depositAddress),
      value: core.toNano('0.038'),
    }).catch(err => {
      console.log('Error -> ', err)
      return err
    })
    console.log('res', res)
  }

  return (
    <div className={styles.depositForm}>
      <Typography.Text className={styles.title}>Amount</Typography.Text>
      <AppInputNumber
        decimals={true}
        value={amount}
        onChange={val => setAmount(val)} />
      <AppButton color={'success'} onClick={handleDeposit}>
        Deposit
      </AppButton>
    </div>
  )
}

export default DepositForm
