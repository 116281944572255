import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Space, Tag } from 'antd'
import cn from 'classnames'

import styles from './styles.module.scss'
import { getEvents } from '../../api'
import $events from '../../store/events'
import $app from '../../store/app'
import EventCard from '../../components/EventCard'

const MainScreen = () => {
  const dispatch = useDispatch()
  const events = useSelector(({events}) => events.data)
  const tags = useSelector(({events}) => events.tags)
  const activeCategory = useSelector(({events}) => events.activeCategory)

  const [activeTag, setActiveTag] = useState(null)
  const [order, setOrder] = useState('volume24hr')

  useEffect(() => {
    setActiveTag(null)
  }, [activeCategory])

  useEffect(() => {
    if (activeCategory) {
      setOrder(activeCategory.slug === 'new-events' ? 'startDate' : 'volume24hr')
    }
  }, [activeCategory])

  useEffect(() => {
    const tag = activeTag ? activeTag.slug : (activeCategory.slug === 'top-events' || activeCategory.slug === 'new-events' ? null : activeCategory.slug)
    getEvents({order: order, ...(tag ? {tag_slug: tag} : null)}).then(res => {
      if (res) {
        dispatch($events.set.setEvents(res))
        const tags = res.sort((a,b) => a.featured ? -1 : 1).flatMap(e => e.tags).filter(tag => tag)
        dispatch($events.set.setTags(tags))
      }
    })
  }, [order, activeTag, dispatch, activeCategory])

  const handleChangeTag = (checked, tag) => {
    setActiveTag(tag)
  }

  const handleClickBet = (outcomeIndex, market) => {
    dispatch($app.set.setForm({show: true, market: market, outcomeIndex: outcomeIndex}))
  }

  return (
    <div className={styles.container}>
      <div className={styles.tags}>
        {
          ['volume24hr', 'startDate'].map(o => {
            const isActive = o === order && !activeTag
            return (
              <Tag.CheckableTag
                key={o}
                className={cn(styles.tag, {[styles.active]: isActive})}
                checked={isActive}
                onChange={checked => setOrder(o)}>
                { o === 'volume24hr' ? 'Top' : 'New' }
              </Tag.CheckableTag>
            )
          })
        }
        {
          tags.filter(tag => tag.forceShow).map(tag => {
            return (
              <Tag.CheckableTag
                key={tag.id}
                className={cn(styles.tag, {[styles.active]: tag.id === activeTag?.id})}
                checked={tag.id === activeTag?.id}
                onChange={checked => handleChangeTag(checked, tag)}>
                { tag.label }
              </Tag.CheckableTag>
            )
          })
        }
      </div>
      <Space direction="vertical" size={16} style={{width: '100%'}}>
        {
          events.map((event) => {
            return (
              <EventCard key={event.id} {...event} onPressBet={handleClickBet} />
            )
          })
        }
      </Space>
    </div>
  )
}

export default MainScreen
