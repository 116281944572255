export const distinctArrayByKey = (array, key) => {
  return [...new Map(array.map(item => [item[key], item])).values()]
}

export const toNanoton = (amount) => {
  return amount * 10**9
}

export const parseMarket = market => {
  return {
    ...market,
    outcomes: JSON.parse(market.outcomes),
    outcomePrices: JSON.parse(market.outcomePrices),
    clobTokenIds: JSON.parse(market.clobTokenIds),
  }
}

export const wait = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}
