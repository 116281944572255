import { createSlice } from '@reduxjs/toolkit'

export const appSlice = createSlice({
  name: 'orders',

  initialState: {
    data: [],

  },

  reducers: {
    setOrders: (state, action) => {
      state.data = action.payload
    },
  },
})

const slice = {
  reducer: appSlice.reducer,
  set: appSlice.actions,
}

export default slice
