import { useEffect, useRef, useCallback } from 'react'
import { useTonConnectUI } from '@tonconnect/ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { Layout, Drawer, Modal } from 'antd'
import CryptoJS from 'crypto-js'

import styles from './styles.module.scss'
import { generatePayload, checkProof, getBalance, getDepositAddress, getSpread, getActiveOrders } from '../../api'
import useInterval from '../../hooks/useInterval'
import $app from '../../store/app'
import $orders from '../../store/orders'
import AppHeader from '../Header'
import BetForm from '../BetForm'
import DepositForm from '../DepositForm'
import WithdrawForm from '../WithdrawForm'
import Orders from '../Orders'

function signKey (botToken, initData) {
  const secret_key = CryptoJS.HmacSHA256("7383895828:AAH1utPimDOFs8L0F-kq4gjxg3VA411X5Mw", 'WebAppData')
  const hex = CryptoJS.enc.Hex.stringify(CryptoJS.HmacSHA256(initData, secret_key))
  alert(`HEX: ${hex}, HASH: ${initData.split('hash=')[1]}`)
  // import('crypto').then(res => {
  //   console.log(res)
  // })
  // const key = new Buffer(clientKey, 'hex');
  // return crypto.createHmac('sha256', key).update(msg).digest('hex');
}

const { Content } = Layout

const AppLayout = ({ children }) => {
  const dispatch = useDispatch()
  const [tonConnectUI] = useTonConnectUI()

  const accessToken = useSelector(({app}) => app.accessToken)
  const formPreset = useSelector(({app}) => app.formPreset)
  const { showDepositForm, showWihdrawForm, showOrders, requestStatusCode } = useSelector(({app}) => {
    return {
      showDepositForm: app.showDepositForm,
      showWihdrawForm: app.showWithdrawForm,
      showOrders: app.showOrders,
      requestStatusCode: app.requestStatusCode,
    }
  })

  const firstProofLoading = useRef(true)

  const recreateProofPayload = useCallback(async () => {
		if (firstProofLoading.current) {
			tonConnectUI.setConnectRequestParameters({ state: 'loading' })
			firstProofLoading.current = false
		}

		const payload = await generatePayload()

		if (payload) {
			tonConnectUI.setConnectRequestParameters({ state: 'ready', value: payload })
		} else {
			tonConnectUI.setConnectRequestParameters(null)
		}
	}, [tonConnectUI, firstProofLoading])

  if (firstProofLoading.current) {
		recreateProofPayload()
	}

  useInterval(recreateProofPayload, 9 * 60 * 1000)

  useEffect(() => {
    // console.log(window.Telegram.WebApp.initData)
    // const secret_key = HMAC_SHA256("7383895828:AAH1utPimDOFs8L0F-kq4gjxg3VA411X5Mw", "WebAppData")
    // console.log(secret_key)
    
    // signKey("7383895828:AAH1utPimDOFs8L0F-kq4gjxg3VA411X5Mw", window.Telegram.WebApp.initData)
  }, [])

  useEffect(() => {
    if (requestStatusCode === 401 && tonConnectUI.connected) {
      tonConnectUI.disconnect()
    }
  }, [requestStatusCode])

  useEffect(() => {
    getSpread().then(res => {
      if (res && res.message === 'success') {
        dispatch($app.set.setSpread(res.spread))
      }
    })
  }, [dispatch])

  useEffect(() => {
    tonConnectUI.onStatusChange(async w => {
			if (!w) {
        dispatch($app.set.setAccessToken(null))
				return
			}
			if (w.connectItems?.tonProof && 'proof' in w.connectItems.tonProof) {
				const token = await checkProof(w.connectItems.tonProof.proof, w.account)
        if (token) {
          dispatch($app.set.setAccessToken(token))
          return
        }
			}

			if (!accessToken) {
				tonConnectUI.disconnect()
				return
			}
		})
    if (accessToken) {
      getBalance().then(res => {
        if (res && res.message === 'Success') {
          dispatch($app.set.setBalance(res.balance))
        }
      })
      getDepositAddress().then(res => {
        if (res && res.depositAddress) {
          dispatch($app.set.setDepositAddress(res.depositAddress))
        }
      })
      getActiveOrders().then(res => {
        if (res && res.message === 'Success') {
          dispatch($orders.set.setOrders(res.activeOrders))
        }
      })
    }
  }, [tonConnectUI, accessToken, dispatch])

  const handleCloseForm = () => {
    dispatch($app.set.closeForm())
  }

  const handleCloseOrders = () => {
    dispatch($app.set.setShowOrders(false))
  }

  return (
    <Layout className="App">
      <AppHeader />
      <Content className={styles.content}>
        { children }
      </Content>
      <Drawer
        placement={'bottom'}
        closable={false}
        // size="large"
        size={window.navigator.userAgent.toLowerCase().includes('iphone') ? 'large' : 'default'}
        // size={'default'}
        onClose={handleCloseForm}
        open={formPreset.show}
      >
        <BetForm />
      </Drawer>
      <Drawer
        title={'Orders'}
        placement={'bottom'}
        closable={true}
        size="large"
        onClose={handleCloseOrders}
        open={showOrders}
      >
        <Orders />
      </Drawer>
      <Modal
        open={showDepositForm}
        onCancel={() => dispatch($app.set.setDepositForm(false))}
        footer={[
          // <AppButton onClick={() => dispatch($app.set.setDepositForm(false))}>Deposit</AppButton>
        ]}>
        <DepositForm />
      </Modal>
      <Modal
        open={showWihdrawForm}
        onCancel={() => dispatch($app.set.setWithdrawForm(false))}
        footer={[
          // <AppButton onClick={() => dispatch($app.set.setDepositForm(false))}>Deposit</AppButton>
        ]}>
        <WithdrawForm />
      </Modal>
    </Layout>
  )
}

export default AppLayout