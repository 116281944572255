import { Button } from 'antd'
import cn from 'classnames'

import styles from './styles.module.scss'

const AppButton = ({color, children, ...props}) => {
  return (
    <Button className={cn(styles.button, styles[color])} {...props}>
      { children }
    </Button>
  )
}

export default AppButton
