import { API_URL, POLYMARKET_API_URL, GAMMA_API_URL } from '../config'
import store from '../store'
import $app from '../store/app'

const request = async (url, method, params) => {
  const token = store.getState().app.accessToken
  const res = await fetch(
    url,
    {
      method,
      headers: {
        ...(token ? {Authorization: `Bearer ${token}`} : null),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    },
  ).catch(err => {
    console.log('REQUEST ERROR', err)
  })
  store.dispatch($app.set.setRequestStatusCode(res?.status))
  if (!res) {
    return
  }
  if (res.headers.get('Content-Type').includes('application/json')) {
    return await res.json()
  }
  return await res.text()
}

export const generatePayload = async () => {
  const res = await request(`${API_URL}/users/generate-payload`, 'POST', {})
  if (res) {
    return {tonProof: res.payload}
  }
}

export const checkProof = async (proof, account) => {
  const reqBody = {
    address: account.address,
    network: account.chain,
    public_key: account.publicKey,
    proof: {
      ...proof,
      state_init: account.walletStateInit,
    },
  }
  const res = await request(`${API_URL}/users/login`, 'POST', reqBody)
  return res?.token
}

export const getBalance = async () => {
  const res = await request(`${API_URL}/account/get-balance`, 'GET')
  return res
}

export const getDepositAddress = async () => {
  const res = await request(`${API_URL}/account/get-deposit-address`, 'GET')
  return res

}

export const withdraw = async (amount) => {
  const res = await request(`${API_URL}/account/withdraw`, 'POST', {
    amount: amount,
  })
  return res
}

export const getMarkets = async (marketId) => {
  const params = marketId ? `/${marketId}` : ''
  const res = await request(`${POLYMARKET_API_URL}/markets${params}`)
  return res
}

export const getMarketByToken = async (tokenId) => {
  console.log(`${GAMMA_API_URL}/markets?clob_token_ids=${tokenId}`)
  const res = await request(`${GAMMA_API_URL}/markets?clob_token_ids=${tokenId}`)
  return res && Array.isArray(res) && res[0]
}

export const getOrderBooks = async (params) => {
  const res = await request(`${POLYMARKET_API_URL}/books`, 'POST', params)
  return res
}

export const getOrderBook = async (tokenId) => {
  const res = await request(`${POLYMARKET_API_URL}/book?token_id=${tokenId}`)
  return res
}

export const getEvents = async (params) => {
  const searchParams = new URLSearchParams({
    active: true,
    archived: false,
    order: 'volume24hr',
    ascending: false,
    offset: 0,
    closed: false,
    ...params,
  })
  const res = await request(`${GAMMA_API_URL}/events?${searchParams.toString()}`)
  return res
}

export const getTags = async () => {
  const res = await request(`${GAMMA_API_URL}/tags`)
  return res
}

export const createOrder = async (params) => {
  const res = await request(`${API_URL}/orders/create-order`, 'POST', params)
  return res
}

export const getSpread = async () => {
  const res = await request(`${API_URL}/markets/get-spread`)
  return res
}

export const getActiveOrders = async () => {
  const res = await request(`${API_URL}/orders/fetch-active-orders`)
  return res
}

export const cashOutOrder = async (params) => {
  const res = await request(`${API_URL}/orders/cashout-order`, 'POST', params)
  return res
}

