import { createSlice } from '@reduxjs/toolkit'

export const appSlice = createSlice({
  name: 'app',

  initialState: {
    accessToken: localStorage.getItem('api-access-token'),
    balance: 0,
    showDepositForm: false,
    showWithdrawForm: false,
    showOrders: false,
    formPreset: {
      show: false,
      market: null,
    },
    requestStatusCode: null,
  },

  reducers: {
    setAccessToken: (state, action) => {
      if (action.payload) {
        localStorage.setItem('api-access-token', action.payload)
      } else {
        localStorage.removeItem('api-access-token')
      }
      state.accessToken = action.payload
    },
    setBalance: (state, action) => {
      state.balance = action.payload
    },
    setDepositAddress: (state, action) => {
      state.depositAddress = action.payload
    },
    setSpread: (state, action) => {
      state.spread = action.payload
    },
    setDepositForm: (state, action) => {
      state.showDepositForm = action.payload
    },
    setWithdrawForm: (state, action) => {
      state.showWithdrawForm = action.payload
    },
    setShowOrders: (state, action) => {
      state.showOrders = action.payload
    },
    setForm: (state, action) => {
      state.formPreset = action.payload
    },
    closeForm: (state) => {
      state.formPreset = {
        show: false,
        market: null,
      }
    },
    setRequestStatusCode: (state, action) => {
      state.requestStatusCode = action.payload
    },
  },
})

const slice = {
  reducer: appSlice.reducer,
  set: appSlice.actions,

}

export default slice
