import { Typography } from 'antd'
import styles from './styles.module.scss'
import numeral from 'numeral'

import AppButton from '../Button'
import ChanceMeter from '../ChanceMeter'

const { Text } = Typography

const EventCard = ({title, image, markets, onPressBet}) => {
  const handleClickMarket = market => () => {
    console.log(market)
  }

  const handleClickBet = (outcomeIndex, market) => () => {
    onPressBet(outcomeIndex, market)
  }

  const renderStat = () => {
    const prices = markets[0].outcomePrices
    const [priceA] = prices.map(price => Math.round(price*100))
    return (
      <div style={{marginLeft: 'auto'}}>
        <ChanceMeter chance={priceA} />
      </div>
    )
  }
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <img src={image} alt="" className={styles.logo} />
        </div>
        <Text className={styles.title} strong>{ title }</Text>
        {
          markets.length === 1
            ? renderStat()
            : null
        }
      </div>
      <div className={styles.markets}>
        {
          markets.length > 1
            ? [...markets].sort((a,b) => {
              const [priceA] = a.outcomePrices.map(price => Math.round(price*100))
              const [priceB] = b.outcomePrices.map(price => Math.round(price*100))
              return priceB - priceA
            }).map(market => {
                const buttons = market.outcomes
                const [price] = market.outcomePrices.map(price => price*100)
                // const priceFormatted = price < 1 ? `< 1%` : `${price}%`
                const odds = 1/(price/100)
                // console.log(price, odds, market.outcomePrices)
                return (
                  <div key={market.id} className={styles.market}>
                    <Text className={styles.title} strong onClick={handleClickMarket(market)}>{ market.groupItemTitle }</Text>
                    <div className={styles.buttons}>
                      <div style={{fontSize: 12, fontWeight: 'bold', whiteSpace: 'nowrap'}}>{numeral(odds).format('0.0[0]')}</div>
                      {
                        buttons.map((btn, i) => {
                          const isSuccess = i % 2 === 0
                          return (
                            <AppButton key={i} type="primary" onClick={handleClickBet(i, market)} color={isSuccess ? 'success' : 'danger'} size="small">
                              { btn }
                            </AppButton>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              })
            : <div className={styles.buttons}>
                {
                  markets[0].outcomes.map((btn, i) => {
                    const isSuccess = i % 2 === 0
                    return (
                      <AppButton key={i} type="primary" onClick={handleClickBet(i, markets[0])} color={isSuccess ? 'success' : 'danger'}>
                        Bet { btn }
                      </AppButton>
                    )
                  })
                }
              </div>
        }
      </div>
    </div>
  )
}

export default EventCard
