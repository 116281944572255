import { Progress } from 'antd'

const ChanceMeter = ({ chance }) => {
  return (
    <Progress
      type="dashboard"
      percent={chance}
      strokeColor={chance < 20 ? '#f5222d' : chance < 50 ? 'rgba(230, 72, 0, 0.8)' : '#52c41a'}
      size="small" />
  )
}

export default ChanceMeter
