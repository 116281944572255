import { createSlice } from '@reduxjs/toolkit'

export const appSlice = createSlice({
  name: 'markets',

  initialState: {
    data: [],
    count: 0,
    limit: 0,
    nextCursor: null,
    tags: [],
  },

  reducers: {
    setMarkets: (state, action) => {
      state.data = action.payload.data
      state.count = action.payload.count
      state.limit = action.payload.limit
      state.nextCursor = action.payload.next_cursor
      state.tags = [...new Set(action.payload.data.map(market => market.tags).flat())]
    },
  },
})

const slice = {
  reducer: appSlice.reducer,
  set: appSlice.actions,
}

export default slice
