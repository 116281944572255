import { TonConnectButton, useTonConnectUI } from '@tonconnect/ui-react'
import { Button } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import numeral from 'numeral'
import cn from 'classnames'

import styles from './styles.module.scss'
import $app from '../../store/app'
import $events from '../../store/events'
import { CATEGORIES } from '../../config'
import { TransferIcon } from '../Icons'

const Header = () => {
  const dispatch = useDispatch()
  const [tonConnectUI] = useTonConnectUI()

  const accessToken = useSelector(({app}) => app.accessToken)
  const balance = useSelector(({app}) => app.balance)
  const depositAddress = useSelector(({app}) => app.depositAddress)
  const activeCategory = useSelector(({events}) => events.activeCategory)

  const orders = useSelector(({orders}) => orders.data)

  const handleDeposit = () => {
    if (!depositAddress) {
      tonConnectUI.disconnect()
      return
    }
    dispatch($app.set.setDepositForm(true))
  }

  const handleWithdraw = () => {
    dispatch($app.set.setWithdrawForm(true))
  }

  const handleClickOrders = () => {
    dispatch($app.set.setShowOrders(true))
  }

  const handleClickCategory = (category) => () => {
    dispatch($events.set.setActiveCategory(category))
  }

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        {
          accessToken
            ? <>
              <div className={styles.balance}>
                  <div>${ numeral(balance).format('0.0[0]') }</div>
                  <div style={{color: '#828282'}}>Balance</div>
                </div>
                <div className={styles.balance} onClick={handleClickOrders}>
                  <div>{ orders.length }</div>
                  <div style={{color: '#828282'}}>Orders</div>
                </div>
                <div className={styles.iconsContainer}>
                  <div className={cn(styles.iconContainer, styles.deposit)} onClick={handleDeposit}>
                    <TransferIcon width={20} height={20} color={'#2EAE60'} />
                    <div>Deposit</div>
                  </div>
                  <div className={cn(styles.iconContainer, styles.withdraw)} onClick={handleWithdraw}>
                    <TransferIcon width={20} height={20} color={'#E64800'} reverted />
                    <div>Withdraw</div>
                  </div>
                </div>
                {/* <Button
                  type="primary"
                  shape="round"
                  style={{height: 40, fontWeight: 700}}
                  onClick={handleDeposit}>
                  Deposit
                </Button> */}
              </>
            : null
        }
        <div style={{fontSize: 12, marginLeft: accessToken ? 0 : 'auto'}}>
          <TonConnectButton />
        </div>
      </div>
      <div className={styles.categories}>
        {
          CATEGORIES.map((category) => {
            const isActive = activeCategory?.id === category.id
            return (
              <div key={category.id} className={cn(styles.category, {[styles.active]: isActive})} onClick={handleClickCategory(category)}>
                { category.label }
                <div className={styles.badge} />
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default Header
