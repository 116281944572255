import { configureStore } from '@reduxjs/toolkit'

import app from './app'
import markets from './markets'
import events from './events'
import orders from './orders'

const store = configureStore({
  reducer: {
    app: app.reducer,
    markets: markets.reducer,
    events: events.reducer,
    orders: orders.reducer,
  },

  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  })
})

export default store
